
import { Vue, Options, prop } from "vue-class-component";
import ModalForm from "@/app/ui/components/modal-form/index.vue";
import { RouteController } from "@/app/ui/controllers/RouteController";
import { ProductController } from "@/app/ui/controllers/ProductController";
import { CommodityController } from "@/app/ui/controllers/CommodityController";
import InputAdornment from "@/app/ui/components/input-adornment/index.vue";
import InputDimension from "@/app/ui/components/input-dimension/index.vue";
import debounce from "lodash/debounce";
import { TariffController } from "@/app/ui/controllers/TariffController";
import { RequestListCommodity } from "@/data/payload/api/CommodityApiRequest";
import { AccountController } from "@/app/ui/controllers/AccountController";

class Props {
  isShow = prop<boolean>({
    type: Boolean,
    default: false,
  });
  hiddenProduct = prop<boolean>({
    type: Boolean,
    default: false,
  });
}

@Options({
  components: {
    ModalForm,
    RouteController,
    CommodityController,
    InputAdornment,
    InputDimension,
  },
})
export default class extends Vue.with(Props) {
  /* eslint-disable @typescript-eslint/camelcase */

  mounted() {
    this.filterData.commodity = {
        commodity_id: "",
        commodity_name: this.defaultOptionCommodity,
      };
    this.filterData.commodityId = this.defaultOptionCommodity;
    this.filterData.productName = this.defaultOptionProduct;
  }
  isTerminated = false;
  filterData = {
    commodity: {
      commodity_id: "",
      commodity_name: "",
    },
    commodityId: "",
    productName: "Semua Produk",
  };

  goDownload() {
    TariffController.downloadTariff({
      commodityId:
        this.filterData.commodityId === this.defaultOptionCommodity
          ? ""
          : this.filterData.commodityId,

      productType:
        this.filterData.productName === this.defaultOptionProduct
          ? ""
          : this.filterData.productName,
    });
  }

  onClose() {
    this.isTerminated = true;
    this.$emit("update:isShow", false);
  }

  outCancel() {
    this.isTerminated = false;
    this.$emit("update:isShow", true);
  }

  outBack() {
    this.productName = this.defaultOptionProduct;
    this.filterData.commodity = {
      commodity_id: "",
      commodity_name: "Semua Komoditas",
    };
    this.isTerminated = false;
    this.$emit("update:isShow", false);
  }

  get isDisabled() {
    return !this.productName;
  }

  get defaultOptionProduct() {
    return this.$t("shipmentRates.defaultOptionProduct");
  }

  get defaultOptionCommodity() {
    return this.$t("shipmentRates.defaultOptionCommodity");
  }

  // product section
  get productListData() {
    const defaultOption = {
      name: this.defaultOptionProduct,
      value: "Semua Produk",
    };
    const products = ProductController.productData.data;
    if (products.length > 0) {
      const newPrducts = [defaultOption, ...products];
      const result = this.hiddenProduct
        ? newPrducts.filter((e: any) => e.name !== "  ONEPACK")
        : newPrducts;
      return this.isAccountCountryOutsideID
        ? [{ name: "INTERPACK", value: "INTERPACK" }]
        : result;
    } else return [defaultOption];
  }

  productSelect = false;
  onOpenProductSelect(value: boolean) {
    this.productSelect = value;
  }

  productName = "Semua Produk";
  onSelectProduct(name: string) {
    this.productName = name;
    this.filterData.productName = name;
    this.onOpenProductSelect(false);
  }

  // commodity
  onGetCommodityList(search: string) {
    CommodityController.getCommodityList(
      new RequestListCommodity({
        search: search,
      })
    );
  }
  fetchCommodity = debounce((search: string) => {
    this.onGetCommodityList(search);
  }, 250);

  get loadingCommodity() {
    return CommodityController.isLoading;
  }

  get isAccountCountryOutsideID() {
    return (
      AccountController.accountData.account_type_detail.countryCode.toLowerCase() !==
      "id"
    );
  }

  get commodityList() {
    return [
      {
        commodity_id: "",
        commodity_name: this.defaultOptionCommodity,
      },
      ...CommodityController.commodities.data
        .filter((key) =>
          this.isAccountCountryOutsideID ? !!key.commodity_name_en : true
        )
        .map((key) => ({
          ...key,
          commodity_name: this.isAccountCountryOutsideID
            ? key.commodity_name_en
            : key.commodity_name,
        })),
    ];
  }

  onChangeCommodity(value: any) {
    if (!value) {
      this.filterData.commodity = {
        commodity_id: "",
        commodity_name: this.defaultOptionCommodity,
      };
      this.filterData.commodityId = "";
    } else {
      this.filterData.commodity = value;
      this.filterData.commodityId = value.commodity_id;
    }
  }
}
